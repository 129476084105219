<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-else-if="!loading">
          <crud-list
            v-model="fornecedores"
            :headers="headers"
            :actions="actions"
            :permission="'list_fornecedor'"
            :sort-by="['codigo']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template
              v-if="$store.getters.hasPermission('inserir_fornecedor')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  color="primary"
                  title="Cadastrar novo fornecedor"
                  rounded
                  @click="$router.push({ path: 'fornecedores/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import FornecedoresStore, { BOOTSTRAP, INATIVAR_FORNECEDOR } from '@/store/modules/fornecedores'
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    components: {
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar fornecedor',
            color: 'info darken-3',
            click: item => this.getFornecedor(item),
            icon: 'mdi-pencil',
            permission: 'editar_fornecedor',
          },
          {
            title: 'Excluir Fornecedor',
            color: 'red darken-3',
            click: item => this.INATIVAR_FORNECEDOR(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_fornecedor',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'left', sortable: true, groupable: false, text: 'Código', value: 'codigo', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Razão Social/Nome', value: 'razao_social', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome Fantasia', value: 'nome_fantasia', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'CNPJ', value: 'cnpj', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'CPF', value: 'cpf', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'RG', value: 'rg', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Email', value: 'email', width: 'auto', floatingfilter: true, report: true, styleReport: { cellWidth: 65 } },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true, styleReport: { cellWidth: 30 }, styleXLSX: { type: 'telefone' } },
          { align: 'left', sortable: true, groupable: false, text: 'Logradouro', value: 'endereco.logradouro', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Número', value: 'endereco.numero', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Complemento', value: 'endereco.complemento', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Bairro', value: 'endereco.bairro', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: true, text: 'Cidade', value: 'endereco.cidade.nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Estado', value: 'endereco.estado.sigla', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'CEP', value: 'endereco.cep', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('fornecedores', ['loading', 'fornecedores']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('fornecedores')) { this.$store.registerModule('fornecedores', FornecedoresStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('fornecedores', [BOOTSTRAP, INATIVAR_FORNECEDOR]),
      getFornecedor ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/fornecedores/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
